<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" title="配置优惠券二维码" :visible.sync="isShow" width="650px" :append-to-body="true"
             :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" label-width="150px">
          <el-form-item label="收款二维码:">
            <el-upload :limit="1" class="upload-demo" drag :action="sa.cfg.api_url+'/v1/index/deQrcode'"
                       :on-success="handleImgSuccess">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb(赞不支持小程序码)</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="收款二维码地址:">
            <el-input v-model="m.couponQrcode" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="二维码图片:">
            <img v-show="m.couponQrcode" :src="sa.cfg.api_url+'/v1/index/enQrcode?url='+m.couponQrcode"
                 alt="图片未上传" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="danger" @click="remove()">删 除</el-button>
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
import sa from '@/static/sa'

export default {
  computed: {
    sa () {
      return sa
    },
  },
  data () {
    return {
      isShow: false,
      id: 0,
      m: {
        alipayConfigId: 0,
        couponQrcode: '',
      },
    }
  },
  methods: {
    // 打开
    open: function (data) {
      this.m.alipayConfigId = data.id
      this.m.couponQrcode = data.couponQrcode
      this.isShow = true
    },
    // 表单验证
    submit_check: function () {
      return 'ok'
    },
    handleImgSuccess: function (res) {
      this.m.couponQrcode = res.data
      console.log(this.m.couponQrcode)
    },
    ok: function () {
      // 表单验证
      if (this.submit_check() != 'ok') {
        return
      }
      // 开始增加或修改
      let self = this
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/alipay/coupon/config/upsert',
        this.m,
        function () {
          self.sa.alert('添加成功', function () {
            self.$parent.f5() // 父视图刷新
            self.isShow = false
            // self.claerData();
          })
        },
        defaultCfg,
      )
    },
    remove () {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      }
      let self = this
      this.sa.ajax(
        '/v1/alipay/coupon/config/delete/' + this.m.alipayConfigId,
        function () {
          self.sa.alert('删除成功', function () {
            self.$parent.f5() // 父视图刷新
            self.isShow = false
          })
        },
        defaultCfg,
      )
    },
    claerData () {
      this.m.alipayConfigId = 0
      this.m.couponQrcode = ''
    },
  },
  created: function () {
  },
}
</script>
